"use client";

import * as React from "react";
import Link from "next/link";
import { registry } from "@/registry";
import { LockClosedIcon } from "@radix-ui/react-icons";
import { ChevronRight } from "lucide-react";
import { useSession } from "next-auth/react";
import useSWR from "swr";

import { cn, fetcher, humanize } from "@/lib/utils";
import { Badge } from "@/components/ui/badge";
import { buttonVariants } from "@/components/ui/button";
import { Separator } from "@/components/ui/separator";
import { Skeleton } from "@/components/ui/skeleton";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import BlockWrapper from "@/components/block-wrapper";
import ComponentWrapper from "@/components/component-wrapper";

interface BlockPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  align?: "center" | "start" | "end";
  free?: boolean;
  scroller?: boolean;
}

export function BlockPreview({
  name,
  children,
  className,
  align = "start",
  free = false,
  scroller = false,
  ...props
}: BlockPreviewProps) {
  const { status } = useSession();
  const [paywall, setPaywall] = React.useState(false);
  const { data, isLoading } = useSWR(
    status === "authenticated" && "/api/me",
    fetcher,
  );

  const Codes = React.Children.toArray(children) as React.ReactElement[];
  const Code = Codes[0]; // first child

  const Preview = React.useMemo(() => {
    const Component = registry[name]?.component;

    if (!Component) {
      return (
        <p className="text-sm text-muted-foreground">
          Component{" "}
          <code className="relative rounded bg-muted px-[0.3rem] py-[0.2rem] font-mono text-sm">
            {name}
          </code>{" "}
          not found in registry.
        </p>
      );
    }

    return <Component />;
  }, [name]);

  React.useEffect(() => {
    const shouldShowPaywall =
      (!free && status === "unauthenticated") ||
      (data?.user?.customer?.payments?.length === 0 &&
        data?.user?.role !== "ADMIN");
    setPaywall(shouldShowPaywall);
  }, [free, status, data]);

  if (status === "loading") return null;
  if (isLoading) return null;

  return (
    <div
      className={cn(
        "not-prose relative my-4 flex flex-col space-y-2 lg:max-w-[120ch]",
        className,
      )}
      {...props}
    >
      <Tabs defaultValue="preview" className="relative mr-auto w-full">
        <div className="flex items-center justify-between">
          {paywall && (
            <>
              <div className="flex flex-row gap-2">
                <h2 className="text-xl font-semibold text-primary">
                  {humanize(name)}
                </h2>
                <Badge
                  variant="secondary"
                  className="hidden tracking-normal text-foreground md:inline-flex"
                >
                  Preview
                </Badge>
              </div>
              <Link
                href={"/#pricing"}
                className={cn(
                  buttonVariants({
                    variant: "rainbow-outline",
                    size: "sm",
                  }),
                  "rounded-full gap-2",
                )}
              >
                <LockClosedIcon className="size-4" />
                <Separator className="h-4" orientation="vertical" /> Get
                Unlimited Access
                <ChevronRight className="ml-1 h-4 w-4 text-muted-foreground" />
              </Link>
            </>
          )}
          {!paywall && (
            <>
              <h2 className="text-xl font-semibold text-primary">
                {humanize(name)}
              </h2>
              <TabsList>
                <TabsTrigger value="preview">Preview</TabsTrigger>
                <TabsTrigger value="code">Code</TabsTrigger>
              </TabsList>
            </>
          )}
        </div>
        <TabsContent value="preview" className="relative rounded-md">
          {align === "center" ? (
            <ComponentWrapper>
              <React.Suspense
                fallback={
                  <div className="space-y-4">
                    <Skeleton className="h-[150px] w-full" />
                  </div>
                }
              >
                {scroller && (
                  <div className="max-h-[450px] overflow-y-scroll">
                    <div className="h-screen">{Preview}</div>
                  </div>
                )}
                {!scroller && Preview}
              </React.Suspense>
            </ComponentWrapper>
          ) : (
            <BlockWrapper>
              <React.Suspense
                fallback={
                  <div className="space-y-4">
                    <Skeleton className="h-[150px] w-full" />
                  </div>
                }
              >
                {scroller && (
                  <div className="max-h-[450px] overflow-y-scroll">
                    <div className="h-screen">{Preview}</div>
                  </div>
                )}
                {!scroller && Preview}
              </React.Suspense>
            </BlockWrapper>
          )}
        </TabsContent>
        <TabsContent value="code">
          <div className="flex flex-col space-y-4">
            <div className="w-full rounded-md [&_pre]:my-0 [&_pre]:overflow-auto">
              {Code}
            </div>
          </div>
        </TabsContent>
      </Tabs>
    </div>
  );
}
