"use client";

import { motion } from "framer-motion";
import { CheckIcon } from "lucide-react";

import { licenses } from "@/config/pricing";
import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";
import { CheckoutButton } from "@/components/checkout-button";

export default function TemplatePricing({
  name,
  priceId,
  description,
}: {
  name: string;
  priceId: string;
  description: string;
}) {
  const tiers = [
    {
      name: name,
      id: "tier-single",
      href: "/#pricing",
      price: `$69`,
      priceId: priceId,
      description: description,
      features: [
        "Buy once, use forever for unlimited projects",
        "Future updates are included in the original purchase price",
        "Template is provided as a zip file",
      ],
      cta: "Get Access",
      featured: false,
      isMostPop: false,
    },
    {
      priceId: licenses.individual.priceId,
      name: "All-Access License",
      id: "tier-all-access",
      href: "/#pricing",
      price: "$199",
      description:
        "Get access to Magic UI Pro and every website template as an individual and start building your next idea.",
      features: [
        "Over 50+ sections — Everything you need to build beautiful dashboards, data apps and beyond",
        "3+ templates — Templates built with React, Next.js, and TailwindCSS",
        "Available as Team License — Get a team license and invite up to 10 members",
        "Commercial Use — Use the templates for commercial projects",
        "Perpetual license — You own the template, forever",
        "Lifetime access — Get access to everything available today, plus everything added in the future",
        "Lifetime updates — Get access to all future updates for the original purchase price",
      ],
      cta: "Get all-access",
      featured: true,
      coupon: licenses.individual.coupon,
      isMostPop: true,
    },
  ];
  return (
    <section id="pricing">
      <div className="relative container mx-auto px-4 py-8 lg:py-16 max-w-7xl">
        <div className="mx-auto max-w-2xl text-center xl:max-w-4xl">
          <h2 className="text-base font-semibold leading-7 text-primary">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-foreground sm:text-5xl">
            Buy one, or buy them all.
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-muted-foreground">
          Whether you need a single template or our entire collection, we've got
          you covered. Enjoy lifetime updates and use on unlimited projects,
          personal or commercial.
        </p>
        <div className="mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 xl:max-w-4xl xl:grid-cols-2">
          {tiers.map((tier, tierIdx) => (
            <motion.div
              key={tier.id}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5, delay: 0.3 + tierIdx * 0.2 }}
              className={cn(
                tier.featured
                  ? "relative bg-background shadow-2xl"
                  : "bg-background/60 sm:mx-8 lg:mx-0",

                tier.featured
                  ? ""
                  : tierIdx === 0
                    ? "rounded-t-3xl sm:rounded-b-none xl:rounded-bl-3xl xl:rounded-tr-none"
                    : "sm:rounded-t-none xl:rounded-bl-none xl:rounded-tr-3xl",
                "rounded-3xl p-8 ring-1 ring-border sm:p-10",
              )}
            >
              <h3
                id={tier.id}
                className="text-base font-semibold leading-7 text-primary"
              >
                {tier.name}
              </h3>
              <p className="mt-4 flex items-baseline gap-x-2">
                <span className="text-5xl font-bold tracking-tight text-foreground">
                  {tier.price}
                </span>
              </p>
              <span className="text-xs font-semibold leading-6 text-muted-foreground mt-2">
                One-time payment
              </span>
              <p className="mt-6 text-base leading-7 text-muted-foreground">
                {tier.description}
              </p>
              <ul
                role="list"
                className="mt-8 space-y-3 text-sm leading-6 text-muted-foreground sm:mt-10"
              >
                {tier.features.map((feature) => (
                  <li key={feature} className="flex gap-x-3">
                    <CheckIcon
                      aria-hidden="true"
                      className={cn(
                        tier.featured
                          ? "h-6 p-1 w-6 flex-none text-green-600 bg-green-200 rounded-full"
                          : "h-6 p-1 w-6 flex-none text-muted-foreground bg-muted rounded-full",
                      )}
                    />
                    {feature}
                  </li>
                ))}
              </ul>
              <CheckoutButton
                priceId={tier.priceId}
                className={cn(
                  buttonVariants({
                    variant: tier.isMostPop ? "rainbow" : "outline",
                    size: "lg",
                  }),
                  "mt-6 w-full gap-2",
                )}
                coupon={tier.coupon ?? undefined}
              >
                {tier.cta}
              </CheckoutButton>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}
