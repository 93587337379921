"use client";

import { useEffect, useState } from "react";
import { formatDistanceToNow } from "date-fns";
import { motion } from "framer-motion";
import { toast } from "sonner";

const TimelineItem = ({
  time,
  children,
}: {
  time: string;
  children: React.ReactNode;
}) => (
  <motion.div
    className="flex gap-x-2 overflow-hidden"
    variants={{
      hidden: { opacity: 0, y: -10 },
      show: { opacity: 1, y: 0, transition: { type: "spring" } },
    }}
  >
    <div className="max-w-32 w-full text-end overflow-hidden">
      <span className="text-xs text-gray-500 dark:text-gray-400">{time}</span>
    </div>
    <div className="relative after:absolute after:bottom-0 after:start-3.5 after:top-7 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 last:after:hidden dark:after:bg-gray-700">
      <div className="relative z-10 flex size-7 items-center justify-center">
        <div className="size-2 rounded-full bg-gray-400 dark:bg-gray-600"></div>
      </div>
    </div>
    <div className="pb-4 pt-0.5 text-left flex-1 truncate">{children}</div>
  </motion.div>
);

export default function TemplateChangelog({
  repo,
  owner,
}: {
  repo: string;
  owner: string;
}) {
  const [loading, setLoading] = useState(false);
  const [commits, setCommits] = useState<any[]>([]);

  useEffect(() => {
    const loadCommits = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.NEXT_PUBLIC_APP_URL}/api/repo/commits`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ repo, owner }),
          },
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCommits(data.commits);
      } catch (error) {
        toast.error("Error occurred while loading commits. Please try again.");
        console.error("error", error);
      } finally {
        setLoading(false);
      }
    };
    loadCommits();
  }, []);

  return (
    <section id="changelog">
      <div className="relative container mx-auto px-4 py-8 lg:py-16 max-w-3xl text-center">
        <div className="flex flex-col items-center justify-center">
          <h2 className="text-base font-semibold leading-7 text-primary">
            Changelog
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-foreground sm:text-5xl">
            Stay up to date with our latest changes
          </p>
          <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-muted-foreground">
            Keep track of our ongoing improvements and new features as we
            continuously enhance our template.
          </p>
        </div>
        <motion.div
          className="relative h-full w-full mt-14"
          initial="hidden"
          whileInView="show"
          animate="show"
          viewport={{ once: false }}
          variants={{
            hidden: {},
            show: {
              transition: {
                staggerChildren: 0.04,
              },
            },
          }}
        >
          {commits.map((commit, idx) => (
            <TimelineItem
              key={idx}
              time={formatDistanceToNow(
                new Date(commit?.commit?.author?.date),
                {
                  addSuffix: true,
                },
              )}
            >
              <h3 className="flex gap-x-1.5 font-semibold text-gray-800 dark:text-white">
                {commit?.commit?.message}
              </h3>
            </TimelineItem>
          ))}
          <div className="absolute bottom-0 h-1/2 w-full bg-gradient-to-b from-transparent to-background" />
        </motion.div>
      </div>
    </section>
  );
}
